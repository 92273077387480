import "./BreadCrumbs.css";

import { Breadcrumbs as MuiBreadcrumbs, Chip, Link, Typography } from "@mui/material";
import React from "react";

/**
 * Defines the Dashboard pages state object.
 *
 * @interface BreadcrumbsProps
 */
interface BreadcrumbsProps {
  /**
   * The name of the current page to display at the end of the breadcrumb.
   *
   * @type { string }
   * @memberof BreadcrumbsProps
   */
  currentPage: string;
}

/**
 * Provides the breadcrumbs element for use in the sub pages.
 *
 * @export
 * @class Breadcrumbs
 * @extends { React.Component<BreadcrumbsProps, object> }
 */
export class Breadcrumbs extends React.Component<BreadcrumbsProps, object> {
  /**
   * The name of the current page to display at the end of the breadcrumb
   *
   * @type { string }
   * @memberof Breadcrumbs
   */
  public currentPage: string;

  /**
   * Creates an instance of Breadcrumbs.
   *
   * @param { BreadcrumbsProps } props
   * @memberof Breadcrumbs
   */
  public constructor(props: BreadcrumbsProps) {
    super(props);

    this.currentPage = props.currentPage;
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { React.ReactNode }
   * @memberof Breadcrumbs
   */
  public render(): React.ReactNode {
    return (
      <MuiBreadcrumbs aria-label="breadcrumb">
        <Link underline="hover" href="/">
          <Chip clickable label="Dashboard" />
        </Link>
        <Typography>{ this.currentPage }</Typography>
      </MuiBreadcrumbs>
    );
  }
}
