import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

import { IButtonProps } from "./Button.interface";

/**
 * The primary button component.
 *
 * @export
 * @class PrimaryButton
 * @extends { React.Component<IButtonProps, object> }
 */
export class PrimaryButton extends React.Component<IButtonProps, object> {
  /**
   * Creates an instance of PrimaryButton.
   *
   * @param { IButtonProps } props
   * @memberof PrimaryButton
   */
  public constructor(props: IButtonProps) {
    super(props);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof PrimaryButton
   */
  public render(): JSX.Element {
    const loadingIcon = <CircularProgress color="inherit" size={20} />;

    const icon = this.props.loading ? (
      loadingIcon
    ) : (
      <span className="material-icons text-white">{this.props.icon}</span>
    );

    const text =
      !this.props.icon && this.props.loading ? (
        loadingIcon
      ) : (
        <Typography className="uppercase" variant="h6">
          <strong>{this.props.text}</strong>
        </Typography>
      );

    return (
      <Button
        className={this.props.classNames ? this.props.classNames : ""}
        style={this.props.style ? this.props.style : undefined}
        variant="contained"
        color="primary"
        fullWidth={this.props.fullWidth}
        type={this.props.type ? this.props.type : "button"}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={this.props.onClick ? this.props.onClick : () => {}}
        disabled={this.props.disabled}
      >
        <Box
          display="flex"
          justifyContent={
            this.props.text && this.props.icon ? "space-between" : "center"
          }
          width="100%"
          alignItems="center"
          sx={{ minHeight: "40px", padding: "8px 0" }}
        >
          {this.props.text && text}

          {this.props.icon && icon}
        </Box>
      </Button>
    );
  }
}
