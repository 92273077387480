import "./Login.css";

import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { parse as qsParse } from 'qs';
import React, { ChangeEvent, KeyboardEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import adeyBackground from "../assets/images/adey_background.png";
import adeyLogo from "../assets/images/adey_logo_white.png";
import { PrimaryButton } from "../components/buttons/PrimaryButton";
import { SecondaryButton } from "../components/buttons/SecondaryButton";
import { ISnackbar } from "../interfaces/SnackbarState.interface";
import AuthService from "../services/Auth.service";
import { rootLogout } from "../store";

/**
 * Defines the shape of the LoginPages state.
 *
 * @interface ILoginPageState
 */
interface ILoginPageState {
  /**
   * The email address of the developer account.
   *
   * @type { string }
   * @memberof ILoginPageState
   */
  readonly email: string;

  /**
   * The password of the developer account.
   *
   * @type { string }
   * @memberof ILoginPageState
   */
  readonly password: string;

  /**
   * Used to set the password input type to either password or text, toggles the eye icon.
   *
   * @type { boolean }
   * @memberof ILoginPageState
   */
  readonly showPassword: boolean;

  /**
   * Defines the state of the snackbar.
   *
   * @type { ISnackbar }
   * @memberof ILoginPageState
   */
  readonly snackbar: ISnackbar;

  /**
   * If the component is loading or awaiting an API response.
   *
   * @type { boolean }
   * @memberof ILoginPageState
   */
  readonly loading: boolean;
}

/**
 * The login page component.
 *
 * @export
 * @class LoginPage
 * @extends { React.Component<WithTranslation, ILoginPageState> }
 */
class LoginPage extends React.Component<
  WithTranslation,
  ILoginPageState
> {
  /**
   * The state object for this component.
   *
   * @memberof LoginPage
   */
  public readonly state = {
    email: "",
    password: "",
    loading: false,
    showPassword: false,
    snackbar: {
      isOpen: false,
      message: "",
    },
  };

  /**
   * Creates an instance of LoginPage.
   *
   * @param { {} } props
   * @memberof LoginPage
   */
  public constructor(props: WithTranslation) {
    super(props);

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleToggleShowPassword = this.handleToggleShowPassword.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleSetLoading = this.handleSetLoading.bind(this);
    this.handleShowSnackbar = this.handleShowSnackbar.bind(this);
    this.handleDismissSnackbar = this.handleDismissSnackbar.bind(this);

    document.title = this.props.t('login.siteTitle');
  }

  /**
   * Causes navigation to the login page to remove user data from state, if it has not already.
   *
   * @return { Promise<void> }
   * @memberof LoginPage
   */
  public async componentDidMount(): Promise<void> {
    rootLogout();

    const token = qsParse(window.location.search, { ignoreQueryPrefix: true }).token;

    if (token && typeof token === 'string') {
      this.setState({ loading: true });
      await AuthService.validateCognitoToken(token);
      this.setState({ loading: false });
    }
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof LoginPage
   */
  public render(): JSX.Element {
    const eyeIcon = this.state.showPassword ? "visibility_off" : "visibility";

    return (
      <main
        className="h-screen flex items-center justify-center"
        style={{ backgroundColor: "rgb(40, 0, 113)" }}
      >
        <img
          src={adeyBackground}
          className="adey-background z-10 w-full fixed bottom-0 h-3/5	bg-cover bg-no-repeat"
          alt={this.props.t('login.imgAlt.adeyBackground')}
        />

        <Box sx={{ p: "28px", minWidth: "600px" }} className="z-10">
          <div>
            <img
              className="mx-auto mb-5"
              style={{ maxHeight: "55px", width: "auto" }}
              src={adeyLogo}
              alt={this.props.t("dashboard.navigation.brand.imgAlt")}
            />

            <div className="block py-8 px-16 bg-white rounded-lg border border-gray-200 shadow-md w-full">
              <h1 className="text-red-600 font-bold text-center mb-5 text-2xl">
                {this.props.t('login.title')}
              </h1>
              <Grid container direction="column" spacing="2">
                <LoginForm
                  email={this.state.email}
                  loading={this.state.loading}
                  password={this.state.password}
                  showPassword={this.state.showPassword}
                  eyeIcon={eyeIcon}
                  handleEmailInput={this.handleEmailInput}
                  handlePasswordInput={this.handlePasswordInput}
                  handleToggleShowPassword={this.handleToggleShowPassword}
                  handleSetLoading={this.handleSetLoading}
                  handleShowSnackbar={this.handleShowSnackbar}
                  t={this.props.t}
                />
              </Grid>
            </div>
          </div>

          <Snackbar
            key={this.state.snackbar.message}
            message={this.state.snackbar.message}
            open={this.state.snackbar.isOpen}
            onClose={this.handleDismissSnackbar}
            autoHideDuration={null}
            action={<Button>{this.props.t('buttons.close')}</Button>}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClick={this.handleDismissSnackbar}
              severity="error"
              variant="filled"
              action={
                <IconButton size="small" onClick={this.handleDismissSnackbar}>
                  <span className="material-icons text-white">close</span>
                </IconButton>
              }
            >
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </main>
    );
  }

  /**
   * Handler method for showing the snackbar alert.
   *
   * @private
   * @param { string } [message]
   * @memberof LoginPage
   */
  private handleShowSnackbar(message?: string): void {
    this.setState({
      snackbar: {
        isOpen: true,
        message:
          message ||
          this.props.t('login.snackbar.unableToLogin'),
      },
    });
  }

  /**
   * Handler method for dismissing the snackbar alert.
   *
   * @private
   * @memberof LoginPage
   */
  private handleDismissSnackbar(): void {
    this.setState({
      snackbar: {
        isOpen: false,
        message: '',
      },
    });
  }

  /**
   * Handler method for toggling the showPassword properties state.
   *
   * @private
   * @memberof LoginPage
   */
  private handleToggleShowPassword(): void {
    this.setState({ showPassword: !this.state.showPassword });
  }

  /**
   * Handler method for the email inputs change event.
   *
   * @private
   * @param { ChangeEvent<HTMLInputElement> } event
   * @memberof LoginPage
   */
  private handleEmailInput(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({ email: event.target.value });
  }

  /**
   * Handler method for the password inputs change event.
   *
   * @private
   * @param { ChangeEvent<HTMLInputElement> } event
   * @memberof LoginPage
   */
  private handlePasswordInput(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({ password: event.target.value });
  }

  /**
   * Handler method for the setting the components loading state.
   *
   * @private
   * @param { boolean } value
   * @memberof LoginPage
   */
  private handleSetLoading(value: boolean): void {
    this.setState({ loading: value });
  }
}

/**
 * Defines the props that will be provided to the login form.
 *
 * @interface ILoginFormProps
 */
interface ILoginFormProps {
  email: string;
  loading: boolean;
  password: string;
  showPassword: boolean;
  eyeIcon: string;
  handleEmailInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePasswordInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handleToggleShowPassword: () => void;
  handleSetLoading: (value: boolean) => void;
  handleShowSnackbar: (message?: string) => void;
  t: (key: string) => string;
}

/**
 * Provides the login form and submit method.
 *
 * @param { ILoginFormProps } props
 * @return { JSX.Element }
 */
function LoginForm(props: ILoginFormProps): JSX.Element {
  /**
   * Handles the login forms submit method.
   *
   * @param { FormEvent } event
   * @return { Promise<void> }
   */
  async function handleSubmit(): Promise<void> {
    props.handleSetLoading(true);

    const { email, password } = props;
    const authenticated = await AuthService.login(email, password);

    if (!authenticated) {
      props.handleShowSnackbar();
    }

    props.handleSetLoading(false);
  }

  /**
   * Handles the password inputs keydown events, calls
   * the handleSubmit method if the enter key is pressed.
   *
   * @param { KeyboardEvent<HTMLDivElement> } event
   */
  function handlePasswordKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.code === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <form>
      <div className="mb-4">
        <TextField
          value={props.email}
          onChange={props.handleEmailInput}
          color="secondary"
          label={props.t('login.inputs.email')}
          variant="outlined"
          disabled={props.loading}
          fullWidth={true}
        ></TextField>
      </div>

      <div>
        <TextField
          value={props.password}
          onKeyDown={handlePasswordKeyDown}
          onChange={props.handlePasswordInput}
          color="secondary"
          label={props.t('login.inputs.password')}
          variant="outlined"
          fullWidth={true}
          disabled={props.loading}
          type={props.showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <button
                  onClick={props.handleToggleShowPassword}
                  className="material-icons"
                  type="button"
                  disabled={props.loading}
                >
                  {props.eyeIcon}
                </button>
              </InputAdornment>
            ),
          }}
        ></TextField>

        <a href={process.env.REACT_APP_FORGOT_PASSWORD_LINK}>
          <SecondaryButton text={props.t('login.buttons.forgotPassword')}></SecondaryButton>
        </a>
      </div>

      <br />

      <Grid item>
        <PrimaryButton
          onClick={handleSubmit}
          disabled={props.loading}
          text={props.t('buttons.continue')}
          icon="trending_flat"
          loading={props.loading}
          fullWidth
          type="button"
        ></PrimaryButton>
      </Grid>
    </form>
  );
}

export default withTranslation()(LoginPage);