import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';

import cognito, { ICognitoState, logout } from './cognito';
import developer, { deleteDeveloper, IDeveloperState } from './developer';
import organisation, { deleteOrganisation, IOrganisationState } from './organisation';
import webhook, { deleteWebhook, IWebhookState } from './webhook';

/**
 * Defines the shape of the stores root state.
 *
 * @export
 * @interface IRootAppState
 */
export interface IRootAppState {
  readonly cognito: ICognitoState;
  readonly developer: IDeveloperState;
  readonly organisation: IOrganisationState;
  readonly webhook: IWebhookState;
}

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['cognito'],
};

const authPersistConfig = {
  key: 'cognito',
  storage: storage,
  whitelist: []
}

export const rootLogout = async () => {
  store.dispatch(logout());
  store.dispatch(deleteDeveloper());
  store.dispatch(deleteOrganisation());
  store.dispatch(deleteWebhook());
}

const rootReducer = combineReducers({
  cognito: persistReducer(authPersistConfig, cognito),
  developer,
  organisation,
  webhook,
});

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
  })

export default store;
export const persistor = persistStore(store);

