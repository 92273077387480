import Api from "../api/Api";
import IDeveloper from "../interfaces/Developer.interface";
import store from "../store";
import { acceptTermsAndConditions } from "../store/developer";
import { generateRandomToken } from "../util/generate-random-token";
import AuthService from "./Auth.service";

/**
 * Provides methods for working with developer data.
 *
 * @export
 * @class DeveloperService
 */
export default class DeveloperService {
    /**
     * Accepts the terms and conditions.
     *
     * Generates a random developer token.
     *
     * @static
     * @return { Promise<boolean> }
     * @memberof DeveloperService
     */
    public static async acceptTermsAndConditions(): Promise<boolean> {
        try {
            const { organisation } = store.getState();

            await Api.post<IDeveloper>(`/organisation/${organisation.id}/developer/accept-terms-and-conditions`, {
                token: generateRandomToken(),
            });

            store.dispatch(acceptTermsAndConditions());

            await AuthService.me();

            return true
        } catch (error) {
            return false;
        }
    }
}