import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Breadcrumbs } from "../../components/BreadCrumbs";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { SecondaryButton } from "../../components/buttons/SecondaryButton";
import { ISnackbar } from "../../interfaces/SnackbarState.interface";
import IWebhook from "../../interfaces/Webhook.interface";
import AuthService from "../../services/Auth.service";
import WebhookService from "../../services/Webhook.service";
import { IRootAppState } from "../../store";
import { ICognitoState } from "../../store/cognito";
import { IDeveloperState } from "../../store/developer";
import { IOrganisationState } from "../../store/organisation";
import { IWebhookState } from "../../store/webhook";
import { generateRandomToken } from "../../util/generate-random-token";

/**
 * Defines the shape of the Settings props.
 *
 * @interface ISettingsProps
 */
interface ISettingsProps {
  cognito: ICognitoState;
  developer: IDeveloperState;
  organisation: IOrganisationState;
  webhook: IWebhookState;
}

/**
 * Defines the shape of the Settings page webhook state object.
 *
 * @interface ISettingsWebhookState
 * @extends { IWebhook }
 */
interface ISettingsWebhookState extends IWebhook {
  /**
   * If the webhook is saved to the API.
   *
   * @type { boolean }
   * @memberof ISettingsWebhookState
   */
  isSaved: boolean;
}

/**
 * Defines the shape of the Settings page developer state object.
 *
 * @interface ISettingsDeveloperState
 */
interface ISettingsDeveloperState {
  /**
   * The current number of requests this developer account has made
   *
   * @type { number }
   * @memberof ISettingsDeveloperState
   */
  requestCount: number;

  /**
   * The request limit on this developer account, if requestCount is equal to it requests will be denied
   *
   * @type { number }
   * @memberof ISettingsDeveloperState
   */
  requestLimit: number;
}

/**
 * Defines the shape of the Settings state.
 *
 * @interface ISettingsState
 */
interface ISettingsState {
  /**
   * The local developer state.
   *
   * @type { ISettingsDeveloperState }
   * @memberof ISettingsState
   */
  readonly developer: ISettingsDeveloperState;

  /**
   * If the token refresh warning dialog should be shown.
   *
   * @type { boolean }
   * @memberof ISettingsState
   */
  readonly refreshTokenDialogIsOpen: boolean;

  /**
   * If the component is loading or awaiting a response from the API.
   *
   * @type { boolean }
   * @memberof ISettingsState
   */
  readonly loading: boolean;

  /**
   * Defines the state of the snackbar.
   *
   * @type { ISnackbar }
   * @memberof ISettingsState
   */
  readonly snackbar: ISnackbar;

  /**
   * The developers authentication token.
   *
   * @type { string }
   * @memberof ISettingsState
   */
  readonly token: string;

  /**
   * The local state of the webhook.
   *
   * @type { ISettingsWebhookState }
   * @memberof ISettingsState
   */
  readonly webhook: ISettingsWebhookState;
}

/**
 * Defines the Settings pages props object that will be provided on initialisation.
 */
type Props = ISettingsProps & WithTranslation;

/**
 * The dashboards settings view component.
 *
 * Handles displaying and updating the webhook.
 *
 * @export
 * @class Settings
 * @extends { React.Component<Props, ISettingsState> }
 */
class Settings extends React.Component<Props, ISettingsState> {
  /**
   * The state object for this component.
   *
   * @memberof Settings
   */
  public readonly state = {
    developer: {
      requestCount: 0,
      requestLimit: 0,
    },
    refreshTokenDialogIsOpen: false,
    loading: false,
    token: "",
    snackbar: {
      isOpen: false,
      message: "",
    },
    webhook: {
      url: "",
      isSaved: false,
    },
  };

  /**
   * Creates an instance of Settings.
   *
   * @param { Props } props
   * @memberof Settings
   */
  public constructor(props: Props) {
    super(props);

    this.handleUpdateWebhook = this.handleUpdateWebhook.bind(this);
    this.handleDeleteWebhook = this.handleDeleteWebhook.bind(this);
    this.handleUrlInput = this.handleUrlInput.bind(this);
    this.handleRefreshToken = this.handleRefreshToken.bind(this);
    this.handleConfirmRefreshToken = this.handleConfirmRefreshToken.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleRefreshToken = this.handleRefreshToken.bind(this);
    this.handleDismissSnackbar = this.handleDismissSnackbar.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    if (!this.props.organisation.id) {
      await AuthService.me();
    }

    this.setState({ loading: true });

    const webhook = await WebhookService.get();

    if (webhook) {
      this.setState({
        loading: false,
        token: webhook.token,
        webhook: {
          url: webhook.url || "",
          isSaved: true,
        },
      });
    }
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof Settings
   */
  public render(): JSX.Element {
    const requestLimit = (this.props.developer.requestLimit === null) ? 0 : this.props.developer.requestLimit;
    const requestCount = (this.props.developer.requestCount === null) ? 0 : this.props.developer.requestCount;
    const requestsRemaining = requestLimit - requestCount;
    const lastAuthenticated = this.props.cognito.userAttributes?.lastAuthenticated ?
      new Date(this.props.cognito.userAttributes.lastAuthenticated * 1000).toString() : '';

    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Breadcrumbs
          currentPage={this.props.t("dashboard.navigation.settings")}
        />

        <div className="mt-8 grid grid-cols-12 px-24">
          <div className="col-span-12 mt-4">
            <h4 className="title text-sm font-bold uppercase">
              {this.props.t("settings.inputs.requestsRemaining")}
            </h4>
            <div className="grid grid-cols-11">
              <div className="col-span-11 md:col-span-5">
                <TextField
                  value={requestsRemaining || ""}
                  variant="outlined"
                  className="mui-no-border"
                  fullWidth={true}
                ></TextField>
              </div>

              <div className="col-span-11 md:col-span-5 md:col-start-7">
                <div className="p-4 border border-1 border-grey">
                  <p>{this.props.t("settings.helpText.requestsRemaining")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 mt-4">
            <h4 className="title text-sm font-bold uppercase">
              {this.props.t("settings.inputs.organisationId")}
            </h4>
            <div className="grid grid-cols-11">
              <div className="col-span-11 md:col-span-5">
                <TextField
                  value={this.props.organisation.id || ""}
                  variant="outlined"
                  className="mui-no-border"
                  fullWidth={true}
                ></TextField>
              </div>

              <div className="col-span-11 md:col-span-5 md:col-start-7">
                <div className="p-4 border border-1 border-grey">
                  <p>{this.props.t("settings.helpText.organisationId")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 mt-4">
            <h4 className="title text-sm font-bold uppercase">
              {this.props.t("settings.inputs.token")}
            </h4>
            <div className="grid grid-cols-11">
              <div className="col-span-11 md:col-span-5">
                <TextField
                  style={{ backgroundColor: "#ffffff" }}
                  value={this.state.token}
                  variant="outlined"
                  fullWidth={true}
                  className="mui-no-border"
                  disabled={this.state.loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleRefreshToken}
                        >
                          <span
                            className="material-icons"
                            style={{ color: "var(--primary)" }}
                          >
                            refresh
                          </span>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </div>

              <div className="col-span-11 md:col-span-5 md:col-start-7">
                <div className="p-4 border border-1 border-grey">
                  <p>{this.props.t("settings.helpText.token")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 mt-4">
            <h4 className="title text-sm font-bold uppercase">
              {this.props.t("settings.inputs.url")}
            </h4>
            <div className="grid grid-cols-11">
              <div className="col-span-11 md:col-span-5">
                <TextField
                  style={{ backgroundColor: "#ffffff" }}
                  value={this.state.webhook.url}
                  onChange={this.handleUrlInput}
                  className="mui-no-border"
                  variant="outlined"
                  fullWidth={true}
                  disabled={this.state.loading}
                ></TextField>
              </div>

              <div className="col-span-11 md:col-span-5 md:col-start-7">
                <div className="p-4 border border-1 border-grey">
                  <p>{this.props.t("settings.helpText.webhookUrl")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 mt-4">
            <div className="grid grid-cols-11">
              <div className="col-span-11 md:col-span-5">
                <PrimaryButton
                  onClick={this.handleUpdateWebhook}
                  text={this.props.t("settings.buttons.updateWebhookUrl")}
                  fullWidth
                ></PrimaryButton>
              </div>
            </div>
          </div>
          <div className="col-span-12 mt-12">
            <p className="m-0 text-gray-500 text-sm">{ this.props.t('settings.last-login') }: { lastAuthenticated }</p>
          </div>

          <Dialog
            onClose={this.handleCloseDialog}
            open={this.state.refreshTokenDialogIsOpen}
          >
            <DialogTitle>
              {this.props.t("settings.dialogs.refreshToken.title")}
            </DialogTitle>
            <DialogContent>
              {this.props.t("settings.dialogs.refreshToken.content")}
            </DialogContent>
            <DialogActions
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <SecondaryButton
                onClick={this.handleCloseDialog}
                text={this.props.t("buttons.close")}
              ></SecondaryButton>
              <PrimaryButton
                onClick={this.handleConfirmRefreshToken}
                text={this.props.t("buttons.confirm")}
              ></PrimaryButton>
            </DialogActions>
          </Dialog>

          <Snackbar
            key={this.state.snackbar.message}
            message={this.state.snackbar.message}
            open={this.state.snackbar.isOpen}
            onClose={this.handleDismissSnackbar}
            autoHideDuration={null}
            action={<Button>Close</Button>}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClick={this.handleDismissSnackbar}
              severity="error"
              variant="filled"
              action={
                <IconButton size="small" onClick={this.handleDismissSnackbar}>
                  <span className="material-icons text-white">close</span>
                </IconButton>
              }
            >
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  }

  /**
   * Handler method for showing the snackbar alert.
   *
   * @private
   * @param { string } [message]
   * @memberof Settings
   */
  private handleShowSnackbar(message?: string): void {
    this.setState({
      snackbar: {
        isOpen: true,
        message: message || this.props.t("settings.snackbar.generic"),
      },
    });
  }

  /**
   * Handler method for dismissing the snackbar alert.
   *
   * @private
   * @memberof Settings
   */
  private handleDismissSnackbar(): void {
    this.setState({
      snackbar: {
        isOpen: false,
        message: "",
      },
    });
  }

  /**
   * Handler method for the url inputs on change event.
   *
   * @private
   * @param { ChangeEvent<HTMLInputElement> } event
   * @return { Promise<void> }
   * @memberof Settings
   */
  private async handleUrlInput(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    this.setState({
      webhook: {
        ...this.state.webhook,
        url: event.target.value,
      },
    });
  }

  /**
   * Handler method for the update buttons on click event.
   *
   * @private
   * @return { Promise<void> }
   * @memberof Settings
   */
  private async handleUpdateWebhook(): Promise<void> {
    this.setState({ loading: true });

    const { url } = this.state.webhook;
    const webhook = await WebhookService.update({ url });

    if (webhook) {
      this.setState({
        loading: false,
        webhook: {
          ...webhook,
          isSaved: true,
        },
      });
    } else {
      this.handleShowSnackbar(this.props.t("settings.snackbar.failedToUpdate"));
      this.setState({ loading: false });
    }
  }

  /**
   * Handler method for deleting an organisations webhook.
   *
   * @private
   * @return { Promise<void> }
   * @memberof Settings
   */
  private async handleDeleteWebhook(): Promise<void> {
    this.setState({ loading: true });
    const deleted = await WebhookService.delete();

    if (deleted) {
      this.setState({
        loading: false,
        webhook: {
          url: "",
          isSaved: false,
        },
      });
    }
  }

  /**
   * Handler method for opening the token refresh warning dialog.
   *
   * @private
   * @return { Promise<void> }
   * @memberof Settings
   */
  private async handleCloseDialog(): Promise<void> {
    if (!this.state.loading) {
      this.setState({ refreshTokenDialogIsOpen: false });
    }
  }

  /**
   * Handler method for opening the token refresh warning dialog.
   *
   * @private
   * @return { Promise<void> }
   * @memberof Settings
   */
  private async handleRefreshToken(): Promise<void> {
    this.setState({ refreshTokenDialogIsOpen: true });
  }

  /**
   * Handler method for confirming a token should be refreshed.
   *
   * Updates a developers token and sets the state with the new token.
   *
   * @private
   * @return { Promise<void> }
   * @memberof Settings
   */
  private async handleConfirmRefreshToken(): Promise<void> {
    this.setState({ loading: true, refreshTokenDialogIsOpen: false });

    const token = generateRandomToken();
    const updated = await AuthService.updateDeveloperToken(token);

    if (updated) {
      this.setState({ token, loading: false });
    }
  }
}

/**
 * Maps the stores state to a components props.
 *
 * @param { IRootAppState } {
 *   cognito,
 *   developer,
 *   organisation,
 *   webhook,
 * }
 * @return { ISettingsProps }
 */
const mapStateToProps = ({
  cognito,
  developer,
  organisation,
  webhook,
}: IRootAppState): ISettingsProps => {
  return {
    cognito,
    developer,
    organisation,
    webhook,
  };
};

export default withTranslation()(connect(mapStateToProps)(Settings));
