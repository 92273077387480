import "./Dashboard.css";

import { Button, CircularProgress, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/system";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link, NavLink, Outlet } from "react-router-dom";

import adeyLogo from "../assets/images/adey_logo_white.png";
import { PrimaryButton } from "../components/buttons/PrimaryButton";
import { SecondaryButton } from "../components/buttons/SecondaryButton";
import AuthService from "../services/Auth.service";
import { CognitoService } from "../services/Cognito.service";
import DeveloperService from "../services/Developer.service";
import { IRootAppState, rootLogout } from "../store";
import { ICognitoState } from "../store/cognito";
import { IDeveloperState } from "../store/developer";
import { IOrganisationState } from "../store/organisation";
import TermsAndConditions from "./components/TermsAndConditions";

// Sets the Modals app element to the root element of the application.
Modal.setAppElement("#root");

/**
 * Defines the parts of the stores state that will be provided to the Dashboard.
 *
 * @interface IDashboardProps
 */
interface IDashboardProps {
  readonly cognito: ICognitoState;
  readonly developer: IDeveloperState;
  readonly organisation: IOrganisationState;
}

/**
 * Defines the Dashboard pages state object.
 *
 * @interface IDashboardState
 */
interface IDashboardState {
  /**
   * Used to track the state of the modal, controls if
   * the terms and conditions modal is shown or hidden.
   *
   * @type { boolean }
   * @memberof IDashboardState
   */
  readonly modalIsOpen: boolean;

  /**
   * Used to track if the overlay should be displayed, this is used when refreshing
   * the page so that the developer can be verified and its permissions checked.
   *
   * @type { boolean }
   * @memberof IDashboardState
   */
  readonly overlayIsOpen: boolean;

  /**
   * If the component is currently loading or awaiting an API response.
   *
   * @type { boolean }
   * @memberof IDashboardState
   */
  readonly loading: boolean;
}

/**
 * Defines the Dashboard pages props object that will be provided on initialisation.
 */
type Props = IDashboardProps & WithTranslation;

/**
 * The dashboard page component.
 *
 * Contains the main view of the application and makes use of
 * ReactRouters <Outlet /> to serve the home, documentation and settings views.
 *
 * Wraps the navbar, sidebar and terms and conditions modal components and maintains
 * their state and handler methods.
 *
 * @export
 * @class Dashboard
 * @extends { React.Component<Props, IDashboardState> }
 */
class Dashboard extends React.Component<Props, IDashboardState> {
  /**
   * The state object for this component.
   *
   * @memberof Dashboard
   */
  public readonly state = {
    loading: false,
    overlayIsOpen: false,
    modalIsOpen: false,
  };

  /**
   * Creates an instance of Dashboard.
   *
   * @param { Props } props
   * @memberof Dashboard
   */
  public constructor(props: Props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleGoToPortal = this.handleGoToPortal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAcceptTermsAndConditions =
      this.handleAcceptTermsAndConditions.bind(this);

    document.title = this.props.t("login.siteTitle");
  }

  /**
   * Checks the state contains the organisation id,
   * if it does not the overlay is displayed and auth me is called,
   * on success the overlay is removed and on failure the user is logged out.
   *
   * @return { Promise<void> }
   * @memberof Dashboard
   */
  public async componentDidMount(): Promise<void> {
    if (!this.props.organisation.id) {
      this.setState({ overlayIsOpen: true });
      await AuthService.me();
    }

    if (this.props.organisation.id) {
      this.setState({ overlayIsOpen: false });
    } else {
      this.handleLogout();
    }

    if (!this.props.developer.termsAndConditionsAccepted) {
      this.setState({ modalIsOpen: true });
    }
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof Dashboard
   */
  public render(): JSX.Element {
    return (
      <div id="dashboard" className="App flex flex-col">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.overlayIsOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <header className="App-header grid grid-cols-12 pt-3 pb-20 px-8 items-center">
          <div className="col-span-3 flex items-center">
            <NavLink to="/">
              <Button
                className="flex flex-col text-center"
                style={{ color: "#fff", padding: 0 }}
              >
                <img
                  style={{ maxHeight: 22, width: "auto" }}
                  src={adeyLogo}
                  alt={this.props.t("dashboard.navigation.brand.imgAlt")}
                />

                <h6 className="mb-0 text-sm font-medium leading-7 uppercase">
                  {this.props.t("dashboard.navigation.brand.title")}
                </h6>
              </Button>
            </NavLink>
          </div>

          <ul className="col-start-4 col-end-10 list-none flex align-items justify-between">
            <li>
              <NavLink to="/">
                <Button>
                  <p className="text-lg">
                    {this.props.t("dashboard.navigation.home")}
                  </p>
                </Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/documentation">
                <Button>
                  <p className="text-lg">
                    {this.props.t("dashboard.navigation.documentation")}
                  </p>
                </Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/guide">
                <Button>
                  <p className="text-lg">
                    {this.props.t("dashboard.navigation.guide")}
                  </p>
                </Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings">
                <Button>
                  <p className="text-lg">
                    {this.props.t("dashboard.navigation.settings")}
                  </p>
                </Button>
              </NavLink>
            </li>
          </ul>

          <div className="col-span-3 col-start-11">
            <div className="flex justify-end p-2">
              <Avatar
                className="font-bold"
                sx={{
                  bgcolor: "var(--primary)",
                  fontFamily: "Weissenhof Grotesk",
                  fontSize: "24px",
                }}
              >
                {this.props.organisation.name?.slice(0, 1) || ""}
              </Avatar>
              <div className="flex flex-col text-left ml-2">
                <h4 className="font-bold">
                  {this.props.organisation.name || ""}
                </h4>
                <p
                  onClick={this.handleLogout}
                  className="text-sm upper cursor-pointer leading-6"
                >
                  LOGOUT
                </p>
              </div>
            </div>
          </div>
        </header>

        <div>
          <Box
            sx={{
              height: "50px",
              width: "100%",
              backgroundColor: "var(--bg-color)",
            }}
          />
          <Paper
            elevation={0}
            style={{ marginTop: "-50px" }}
            className="h-full mx-8 p-8"
          >
            <Outlet />
          </Paper>
        </div>

        {/* Terms and conditions modal */}
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={
            this.props.developer.termsAndConditionsAccepted ? true : false
          }
        >
          <TermsAndConditions />

          <div className="absolute p-2.5 bottom-0 right-0 text-right">
            {!this.props.developer.termsAndConditionsAccepted && (
              <Link to="/login" onClick={this.handleLogout} className="mr-5">
                <SecondaryButton
                  text={this.props.t("buttons.logout")}
                ></SecondaryButton>
              </Link>
            )}
            {!this.props.developer.termsAndConditionsAccepted && (
              <PrimaryButton
                onClick={this.handleAcceptTermsAndConditions}
                text={this.props.t("buttons.accept")}
              ></PrimaryButton>
            )}

            {this.props.developer.termsAndConditionsAccepted && (
              <PrimaryButton
                onClick={this.handleCloseModal}
                text={this.props.t("buttons.accept")}
              ></PrimaryButton>
            )}
          </div>
        </Modal>

        <footer
          className="grid grid-cols-12 px-8 w-100 my-6"
          style={{ color: "#280071" }}
        >
          <div className="col-span-12 md:col-span-6 text-center md:text-left">
            <p className="py-1.5 px-2">
              {this.props.t("footer.copyRight")} {new Date().getFullYear()}.{" "}
              {this.props.t("footer.allRights")}
            </p>
          </div>

          <div className="col-span-12 md:col-span-6 flex justify-center md:justify-end">
            <p
              onClick={this.handleGoToPortal}
              className="cursor-pointer py-1.5 px-2"
            >
              {this.props.t("footer.links.sensePortal")}
            </p>

            <p
              onClick={this.handleOpenModal}
              className="cursor-pointer py-1.5 px-2"
            >
              {this.props.t("footer.links.termsAndConditions")}
            </p>
          </div>
        </footer>
      </div>
    );
  }

  /**
   * handler method for accepting the terms and conditions.
   *
   * @private
   * @return { Promise<void> }
   * @memberof Dashboard
   */
  private async handleAcceptTermsAndConditions(): Promise<void> {
    this.setState({ loading: true });

    if (await DeveloperService.acceptTermsAndConditions()) {
      this.handleCloseModal();
    }

    this.setState({ loading: true });
  }

  /**
   * Handler method for opening the terms and conditions modal.
   *
   * @private
   * @memberof Dashboard
   */
  private handleOpenModal(): void {
    this.setState({ modalIsOpen: true });
  }

  /**
   * Handler method for opening a new window and navigating to the portal.
   *
   * Used by the portals footer link.
   *
   * @private
   * @memberof Dashboard
   */
  private handleGoToPortal(): void {
    // const token = this.props.cognito.token;
    const url = process.env.REACT_APP_PORTAL_URL;

    window.open(`${url}`);
    // if (token) {
    //   window.open(`${url}login?token=${token}`);
    // } else {
    //   window.open(`${url}`);
    // }
  }

  /**
   * Handler method for closing the terms and conditions modal.
   *
   * @private
   * @memberof Dashboard
   */
  private async handleCloseModal(): Promise<void> {
    this.setState({ modalIsOpen: false });
  }

  /**
   * Handler method for logging the developer out.
   *
   * @private
   * @memberof Dashboard
   */
  private async handleLogout(): Promise<void> {
    await CognitoService.signOut();
    await rootLogout();
  }
}

/**
 * Maps the stores state to a components props.
 *
 * @param { IRootAppState } { developer, organisation }
 * @return { IDashboardProps }
 */
const mapStateToProps = ({
  cognito,
  developer,
  organisation,
}: IRootAppState): IDashboardProps => {
  return {
    cognito,
    developer,
    organisation,
  };
};

export default withTranslation()(connect(mapStateToProps)(Dashboard));
