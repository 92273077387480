import axios, { AxiosRequestConfig } from "axios";

import { CognitoService } from "../services/Cognito.service";

/**
 * Provides methods for making request requests to the Api.
 *
 * @export
 * @class Api
 */
export default class Api {
  /**
   * The Axios instance that will be used to make any requests.
   *
   * @private
   * @memberof Api
   */
  private static readonly axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ADDRESS}`,
    timeout: 5000,
  });

  /**
   * Makes a GET request to the Api.
   *
   * @static
   * @template T
   * @param { string } path
   * @param { AxiosRequestConfig } [config]
   * @return { Promise<T> }
   * @memberof Api
   */
  public static async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axiosInstance.get<T>(path, config || await this.buildOptions());
    return data;
  }

  /**
   * Makes a POST request to the Api.
   *
   * @static
   * @template T
   * @param { string } path
   * @param { unknown } [payload]
   * @param { AxiosRequestConfig } [config]
   * @return { Promise<T> }
   * @memberof Api
   */
  public static async post<T>(path: string, payload?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axiosInstance.post<T>(path, payload, config || await this.buildOptions());
    return data
  }

  /**
   * Makes a PUT request to the Api.
   *
   * @static
   * @template T
   * @param { string } path
   * @param { unknown } [payload]
   * @return { Promise<T> }
   * @memberof Api
   */
  public static async put<T>(path: string, payload?: unknown): Promise<T> {
    const { data } = await this.axiosInstance.put<T>(path, payload, await this.buildOptions());
    return data
  }

  /**
   * Makes a DELETE request to the Api.
   *
   * @static
   * @template T
   * @param { string } path
   * @return { Promise<T> }
   * @memberof Api
   */
  public static async delete<T>(path: string): Promise<T> {
    const { data } = await this.axiosInstance.delete<T>(path, await this.buildOptions());
    return data
  }

  /**
   * Builds and returns the Axios requests config.
   *
   * @private
   * @static
   * @return { Promise<AxiosRequestConfig> }
   * @memberof Api
   */
  private static async buildOptions(): Promise<AxiosRequestConfig> {
    const cognito = await CognitoService.currentSession();

    let token: string;

    if (cognito.isValid()) {
      token = cognito?.getIdToken()?.getJwtToken() || '';
    } else {
      token = '';
    }

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
}