import { Button } from "@mui/material";
import React from "react";

import { IButtonProps } from "./Button.interface";

/**
 * The primary button component.
 *
 * @export
 * @class PrimaryButton
 * @extends { React.Component<IButtonProps, object> }
 */
export class SecondaryButton extends React.Component<IButtonProps, object> {
  /**
   * Creates an instance of PrimaryButton.
   *
   * @param { IButtonProps } props
   * @memberof SecondaryButton
   */
  public constructor(props: IButtonProps) {
    super(props);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof SecondaryButton
   */
  public render(): JSX.Element {
    return (
      <Button
        className={this.props.classNames ? this.props.classNames : ""}
        style={this.props.style ? this.props.style : undefined}
        variant="text"
        disableElevation
        fullWidth={this.props.fullWidth}
        color="secondary"
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={this.props.onClick ? this.props.onClick : () => {}}
        disabled={this.props.disabled}
      >
        <strong>{this.props.text}</strong>
      </Button>
    );
  }
}
