/**
 * Generates a random string to be used as a token.
 *
 * @export
 * @param { number } [length=32]
 * @return { string }
 */
export function generateRandomToken(length = 32): string {
 const allowedCharacters =
   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
 let result = "";

 for (
   let index = 0, allowedCharactersLength = allowedCharacters.length;
   index < length;
   index++
 ) {
   result += allowedCharacters.charAt(
     Math.floor(Math.random() * allowedCharactersLength)
   );
 }

 return result;
}