import "swagger-ui-react/swagger-ui.css";

import React from "react";
import { WithTranslation,withTranslation } from "react-i18next";
import SwaggerUI from "swagger-ui-react";

import { Breadcrumbs } from "../../components/BreadCrumbs";
import { swaggerDocs } from "../../swagger";

/**
 * The dashboards documentation component.
 *
 * Imports and renders the Swagger documentation.
 *
 * @export
 * @class Documentation
 * @extends { React.Component<WithTranslation, object> }
 */
class Documentation extends React.Component<
  WithTranslation,
  object
> {
  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof Documentation
   */
  public render(): JSX.Element {
    return (
      <div>
        <Breadcrumbs
          currentPage={this.props.t("dashboard.navigation.documentation")}
        />
        <SwaggerUI spec={swaggerDocs} />
      </div>
    );
  }
}

export default withTranslation()(Documentation);
