import Api from "../api/Api";
import IDeveloper from "../interfaces/Developer.interface";
import IOrganisation from "../interfaces/Organisation.interface";
import store from "../store";
import { loadingEnd, loadingStart, login, me } from "../store/cognito";
import { getDeveloper } from "../store/developer";
import { getOrganisation } from "../store/organisation";
import { CognitoService } from "./Cognito.service";

/**
 * Defines the shape of a authentication response payload.
 *
 * @interface IAuthenticationResponse
 */
interface IAuthenticationResponse {
    readonly developer: IDeveloper;
    readonly organisation: IOrganisation;
}

/**
 * Provides methods for working with authentication.
 *
 * @export
 * @class AuthService
 */
export default class AuthService {
    /**
     * Logs in the user with Cognito.
     *
     * If successful the user will be logged into the API,
     * this creates an account for them if one does not already exist.
     *
     * Updates the cognito, developer and organisation state.
     *
     * @static
     * @param { string } email
     * @param { string } password
     * @return { Promise<boolean> }
     * @memberof AuthService
     */
    public static async login(email: string, password: string): Promise<boolean> {
        try {
            const cognito = await CognitoService.signIn({ email, password });

            if (!cognito) {
                return false;
            }

            store.dispatch(loadingStart())

            const jwtToken = cognito.signInUserSession.idToken.jwtToken;

            const userAttributes = await CognitoService.userAttributes();

            if (!userAttributes) {
                store.dispatch(loadingEnd());
                throw new Error('Failed to retrieve users Cognito attributes');
            }

            const {
                developer,
                organisation,
            } = await Api.post<IAuthenticationResponse>('auth/login', {}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

            if (organisation && developer) {
                store.dispatch(login(userAttributes));
                store.dispatch(getDeveloper(developer));
                store.dispatch(getOrganisation(organisation));
                store.dispatch(loadingEnd());
                return true;
            }

            store.dispatch(loadingEnd());
            return false;

        } catch (error) {
            store.dispatch(loadingEnd())
            return false;
        }
    }

    /**
     * Fetches and updates the developer and organisation state.
     *
     * Updates the cognito, developer and organisation state.
     *
     * @static
     * @return { Promise<boolean> }
     * @memberof AuthService
     */
    public static async me(): Promise<boolean> {
        try {
            store.dispatch(loadingStart());

            const userAttributes = await CognitoService.userAttributes();

            if (!userAttributes) {
                throw new Error('Failed to retrieve users Cognito attributes');
            }

            const {
                developer,
                organisation,
            } = await Api.get<IAuthenticationResponse>('auth/me');

            if (organisation) {
                store.dispatch(me(userAttributes));
                store.dispatch(getDeveloper(developer));
                store.dispatch(getOrganisation(organisation));
                store.dispatch(loadingEnd());
                return true;
            }

            store.dispatch(loadingEnd());
            return false;
        } catch (error) {
            store.dispatch(loadingEnd());
            return false;
        }
    }

    /**
     * Checks a given Cognito token is valid for a user using the auth/me
     * endpoint, updates the store with the response if the request is successful.
     *
     * Updates the cognito, developer and organisation state.
     *
     * @static
     * @param { string } token
     * @return { Promise<boolean> }
     * @memberof AuthService
     */
    public static async validateCognitoToken(token: string): Promise<boolean> {
        try {
            store.dispatch(loadingStart());

            const userAttributes = await CognitoService.userAttributes();

            if (!userAttributes) {
                throw new Error('Failed to retrieve users Cognito attributes');
            }

            const {
                developer,
                organisation,
            } = await Api.get<IAuthenticationResponse>('auth/me', {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });

            if (organisation) {
                store.dispatch(me(userAttributes));
                store.dispatch(getDeveloper(developer));
                store.dispatch(getOrganisation(organisation));
                store.dispatch(loadingEnd());
                return true;
            }

            store.dispatch(loadingEnd());
            return false;
        } catch (error) {
            store.dispatch(loadingEnd());
            return false;
        }
    }

    /**
     * Updates the developers token.
     *
     * @static
     * @param { string } token
     * @return { Promise<boolean> }
     * @memberof AuthService
     */
    public static async updateDeveloperToken(token: string): Promise<boolean> {
        try {
            await Api.post<string>('auth/update-token', { token });
            return true;
        } catch (error) {
            return false;
        }
    }
}