import { createSlice } from '@reduxjs/toolkit'

/**
 * Defines the shape of the developer state.
 *
 * @export
 * @interface IDeveloperState
 */
export interface IDeveloperState {
  /**
   * The current number of requests this developer account has made.
   *
   * @type { (number | null) }
   * @memberof IDeveloperState
   */
  requestCount: number | null;

  /**
   * The request limit on this developer account, if requestCount is equal to it requests will be denied.
   *
   * @type { (number | null) }
   * @memberof IDeveloperState
   */
  requestLimit: number | null;

  /**
   * If the developer has accepted the terms and conditions yet.
   *
   * @type { Date | null }
   * @memberof IDeveloperState
   */
  termsAndConditionsAccepted: Date | null;
}

export const developerSlice = createSlice({
  name: 'developer',
  initialState: {
    requestCount: 0,
    requestLimit: 0,
    termsAndConditionsAccepted: null,
  } as IDeveloperState,
  reducers: {
    getDeveloper: (state, { payload }): void => {
      Object.assign(state, payload);
    },
    deleteDeveloper: (state): void => {
      Object.assign(state, {
        requestCount: 0,
        requestLimit: 0,
        termsAndConditionsAccepted: null,
      });
    },
    acceptTermsAndConditions: (state): void => {
      Object.assign(state, {
        termsAndConditionsAccepted: true
      });
    },
  },
})

export const { getDeveloper, deleteDeveloper, acceptTermsAndConditions } = developerSlice.actions;

export default developerSlice.reducer