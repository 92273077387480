import { createSlice } from '@reduxjs/toolkit'

import { CognitoUserAttributes } from '../interfaces/Cognito.interface';


/**
 * Defines the shape of the cognito state.
 *
 * @export
 * @interface ICognitoState
 */
export interface ICognitoState {
  /**
   * If the Cognito state is currently loading.
   *
   * @type { boolean }
   * @memberof ICognitoState
   */
  readonly loading: boolean;

  /**
   * If the Cognito has authenticated the current user.
   *
   * @type { boolean }
   * @memberof ICognitoState
   */
  readonly isAuthenticated: boolean;

  /**
   * The Cognito users attributes.
   *
   * @type { (CognitoUserAttributes | null) }
   * @memberof ICognitoState
   */
  readonly userAttributes: CognitoUserAttributes | null;
}


export const cognitoSlice = createSlice({
  name: 'cognito',
  initialState: {
    isAuthenticated: false,
    loading: false,
    userAttributes: null,
  } as ICognitoState,
  reducers: {
    loadingStart: (state): void => {
      Object.assign(state, { loading: true });
    },
    loadingEnd: (state): void => {
      Object.assign(state, { loading: false });
    },

    login: (state, { payload }: { payload: CognitoUserAttributes }): void => {
      Object.assign(state, { isAuthenticated: true, userAttributes: payload });
    },

    me: (state, { payload }: { payload: CognitoUserAttributes }): void => {
      Object.assign(state, { isAuthenticated: true, userAttributes: payload });
    },

    logout: (state): void => {
      Object.assign(state, { isAuthenticated: false });
    },
  },
})

export const { login, logout, loadingStart, loadingEnd, me } = cognitoSlice.actions;

export default cognitoSlice.reducer