import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import termsAndConditions from "./en/termsAndConditions";
import en from './en/translate.json'

en.translations.termsAndConditionsModal = {
  ...en.translations.termsAndConditionsModal,
  termsAndConditions,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

i18n
  .use(initReactI18next)
  .use(LanguageDetector) 
  .init({
    resources: { en },
    debug: process.env.NODE_ENV !== "production",
    supportedLngs: ['en'],
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
  });

  export default i18n;