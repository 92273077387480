import "./Home.css";

import Icon from "@mui/material/Icon";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

/**
 * Defines the shape of a feature object.
 *
 * Features are used to display the available API features to the developer.
 *
 * @interface Feature
 */
interface Feature {
  /**
   * The label of this feature.
   *
   * @type { string }
   * @memberof Feature
   */
  readonly label: string;

  /**
   * If the developer account has the payment permissions to use this feature.
   *
   * @type { boolean }
   * @memberof Feature
   */
  readonly unlocked: boolean;
}

/**
 * The dashboards home view component.
 *
 * @export
 * @class Home
 * @extends { React.Component<WithTranslation> }
 */
class Home extends React.Component<WithTranslation> {
  /**
   * The features of the API that are available to developers.
   *
   * @type { { api: Feature[]; webhook: Feature[] } }
   * @memberof Home
   */
  public readonly features: { api: Feature[]; webhook: Feature[] } = {
    api: [
      {
        label: this.props.t("home.features.api.labels.filterOk"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.filterNotOk"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.lossOfSignal"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.filterServiceExpiry"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.thresholdGreen"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.thresholdRed"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.lowBatteryIndication"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.lowBatteryCritical"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.proCheckTestExpiry"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.proCheckTestPass"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.proCheckTestFail"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.api.labels.sensePredict"),
        unlocked: true,
      },
    ],
    webhook: [
      {
        label: this.props.t("home.features.webhook.labels.filterOk"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.webhook.labels.filterNotOk"),
        unlocked: true,
      },
      {
        label: this.props.t("home.features.webhook.labels.lossOfSignal"),
        unlocked: true,
      },
    ],
  };

  /**
   * Creates an instance of Home.
   *
   * @param { WithTranslation } props
   * @memberof Home
   */
  public constructor(props: WithTranslation) {
    super(props);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof Home
   */
  public render(): JSX.Element {
    // The index in the api features array to split for the second column.
    const apiFeatureSplitIndex = 7;

    // The api features for the left column.
    const apiFeaturesLeftSide: Feature[] = this.features.api.slice(
      0,
      apiFeatureSplitIndex
    );

    // The api features for the right column.
    const apiFeaturesRightSide: Feature[] =
      this.features.api.slice(apiFeatureSplitIndex);

    return (
      <main>
        <div className="grid grid-cols-12 lg:items-center">
          <div className="col-span-12 lg:col-span-6 p-4">
            <p className="text-center text-lg">
              {this.props.t("home.heading.text")}
              <span className="font-bold">
                {" "}
                {this.props.t("home.heading.bold")}
              </span>
            </p>
          </div>

          <div className="col-span-12 lg:col-span-6 p-4 flex flex-col">
            <div className="p-4 border-black/25 rounded-lg border">
              <h4 className="title uppercase">
                {this.props.t("home.accountType.label")}:
              </h4>
              <h3 className="text-lg">
                {this.props.t("home.accountType.enterprise")}
              </h3>
            </div>
          </div>

          <div className="col-span-12 lg:col-span-6 p-4 h-full">
            <div
              style={{ height: "100%" }}
              className="p-4 border-black/25 rounded-lg border h-full flex flex-col justify-between"
            >
              <div>
                <h4 className="title">
                  {this.props.t("home.features.webhook.title")}
                </h4>
                <ul className="mt-6">
                  {this.features.webhook.map((feat, index) =>
                    this.renderFeature(feat, index)
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-span-12 lg:col-span-6 p-4">
            <div className="p-4 border-black/25 rounded-lg border flex flex-col justify-between">
              <div>
                <h4 className="title">
                  {this.props.t("home.features.api.title")}
                </h4>
                <div className="grid grid-cols-12 mt-6">
                  <div className="col-span-12 lg:col-span-6">
                    {apiFeaturesLeftSide.map((feat, index) =>
                      this.renderFeature(feat, index)
                    )}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    {apiFeaturesRightSide.map((feat, index) =>
                      this.renderFeature(feat, index + apiFeatureSplitIndex)
                    )}
                  </div>
                </div>

                {this.renderFeatureCardFooter()}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  private renderFeature(
    { unlocked, label }: Feature,
    key: number
  ): JSX.Element {
    return (
      <div key={key}>
        <div className="mb-5 flex items-center">
          {unlocked ? (
            <Icon style={{ fontSize: "30px" }} className="icon-green">
              done
            </Icon>
          ) : (
            <Icon style={{ fontSize: "30px" }} className="icon-amber">
              lock
            </Icon>
          )}
          <p className="mb-0 ml-5 text-lg">{label}</p>
        </div>
      </div>
    );
  }

  private renderFeatureCardFooter(): JSX.Element {
    return (
      <div className="col-span-12 mt-4">
        <p>
          {this.props.t("home.features.footer")}:
          <a
            href="mailto:adeydeveloperzone@adey.com"
            className="title underline"
          >
            adeydeveloperzone@adey.com
          </a>
        </p>
      </div>
    );
  }
}

export default withTranslation()(Home);
