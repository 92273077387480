import Api from "../api/Api";
import IWebhook from "../interfaces/Webhook.interface";
import store from "../store";
import { deleteWebhook, setWebhook } from "../store/webhook";

/**
* Defines the shape of a update webhook request object.
 *
 * @interface IUpdateWebhook
 */
interface IUpdateWebhook {
    readonly url: string;
}

/**
 * Defines the shape of a Webhooks response object.
 *
 * @interface IWebhookResponse
 */
interface IWebhookResponse {
    url: string;
    token: string
}

/**
 * Provides methods for working with webhook data.
 *
 * @export
 * @class WebhookService
 */
export default class WebhookService {
    /**
     * Gets the webhooks from the organisation the currently authenticated developers associated with.
     *
     * @static
     * @return { (Promise<IWebhookResponse | null>) }
     * @memberof WebhookService
     */
    public static async get(): Promise<IWebhookResponse | null> {
        try {
            const { organisation } = store.getState();
            const webhook = await Api.get<IWebhookResponse>(`organisation/${organisation.id}/webhook`);

            return webhook
        } catch (error) {
            return null;
        }
    }

    /**
     * Updates a webhook by its organisation and webhook ids.
     *
     * @static
     * @param { IUpdateWebhook } updateWebhook
     * @return { Promise<IWebhook | null> }
     * @memberof WebhookService
     */
    public static async update(updateWebhook: IUpdateWebhook): Promise<IWebhook | null> {
        try {
            const { organisation } = store.getState();
            const webhook = await Api.put<IWebhook>(`organisation/${organisation.id}/webhook`, updateWebhook);

            if (webhook) {
                store.dispatch(setWebhook(webhook));
                return webhook;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    /**
     * Deletes a webhook by its organisation and webhook ids.
     *
     * 
     * @return { Promise<boolean> }
     * @memberof WebhookService
     */
    public static async delete(): Promise<boolean> {
        try {
            const { organisation } = store.getState();
            await Api.delete<IWebhook>(`organisation/${organisation.id}/webhook`);
            store.dispatch(deleteWebhook());
        return true;
        } catch (error) {
            return false;
        }
    }
}