import './TermsAndConditions.css';

import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';

import TermsAndConditionsEn from '../../i18n/en/termsAndConditions';

/**
 * Defines the currently supported languages.
 *
 * @enum { number }
 */
enum Languages {
  en = 'en'
}

/**
 * Defines the shape of the TermsAndCondition state.
 *
 * @interface ITermsAndConditionsState
 */
 interface ITermsAndConditionsState {
  /**
   * The language the component should be using.
   *
   * @type { Languages }
   * @memberof ITermsAndConditionsState
   */
  readonly language: Languages;
}

/**
 * The terms and conditions component.
 *
 * Contains the content to be rendered inside the terms and conditions modal.
 *
 * @export
 * @class TermsAndConditions
 * @extends { React.Component<WithTranslation> }
 */
class TermsAndConditions extends React.Component <WithTranslation, ITermsAndConditionsState> {
  /**
   * The state object for this component.
   *
   * @memberof TermsAndConditions
   */
  public readonly state = {
    language: Languages.en,
  };

  /**
   * Creates an instance of TermsAndConditions.
   *
   * @param { WithTranslation } props
   * @memberof TermsAndConditions
   */
  public constructor(props: WithTranslation) {
    super(props);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof TermsAndConditionsModal
   */
  public render(): JSX.Element {
    return (
      <div
        className="terms-and-conditions"
        style={{ height: "92%", overflow: "scroll" }}
      >
        <TermsAndConditionsLanguage language={this.state.language} />
      </div>
    );
  }
}

/**
 * Gets the translations for the terms and conditions.
 *
 * @param {{ language: Languages }} { language }
 * @return { JSX.Element }
 */
function TermsAndConditionsLanguage({ language }: { language: Languages }): JSX.Element {
  try {      
    switch(language) {
      case 'en':
        return <TermsAndConditionsEn />;
      default:
        return <TermsAndConditionsEn />;
    }
  } catch (error) {
    console.log('Error: ', error);
    return <TermsAndConditionsEn />;
  }
}

export default withTranslation()(TermsAndConditions);