import { createSlice } from '@reduxjs/toolkit'

/**
 * Defines the shape of the webhook state.
 *
 * @export
 * @interface IWebhookState
 */
export interface IWebhookState {
  /**
   * The URL that this webhook will use.
   *
   * @type { (string | null) }
   * @memberof IWebhookState
   */
  url: string | null;
}

export const webhookSlice = createSlice({
  name: 'webhook',
  initialState: {
    url: null,
  } as IWebhookState,
  reducers: {
    setWebhook: (state, { payload }): void => {
      Object.assign(state, payload);
    },
    deleteWebhook: (state): void => {
      Object.assign(state, {
        url: null,
      });
    },
  },
})

export const { setWebhook, deleteWebhook } = webhookSlice.actions;

export default webhookSlice.reducer