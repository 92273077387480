import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import AppRouter from "./AppRouter";
import store, { persistor } from "./store";

/**
 * The root level component.
 *
 * Provides routing for the rest of the application.
 *
 * @export
 * @class App
 * @extends { Component }
 */
export default class App extends React.Component {
  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof App
   */
  public render(): JSX.Element {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
        </PersistGate>
      </Provider>
    );
  }
}