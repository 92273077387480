import { Collapse, List, ListItem, ListItemButton } from "@mui/material";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Breadcrumbs } from "../../components/BreadCrumbs";

/**
 * Defines the guide pages state object.
 *
 * @interface IGuideState
 */
interface IGuideState {
  /**
   * The currently open navigation collapse item.
   *
   * @type { string }
   * @memberof IGuideState
   */
  readonly openNavItem: string;
}

/**
 * The dashboards guide view component.
 *
 * @export
 * @class Guide
 * @extends {React.Component<WithTranslation, IGuideState>}
 */
class Guide extends React.Component<WithTranslation, IGuideState> {
  /**
   * The state object for this component.
   *
   * @memberof Guide
   */
  public readonly state = {
    openNavItem: "return-types",
  };

  /**
   * Creates an instance of Guide.
   *
   * @param { WithTranslation } props
   * @memberof Guide
   */
  public constructor(props: WithTranslation) {
    super(props);

    this.setOpenNavigationItem = this.setOpenNavigationItem.bind(this);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof Guide
   */
  public render(): JSX.Element {
    return (
      <main className="grid grid-cols-12">
        <div className="col-span-12">
          <Breadcrumbs
            currentPage={this.props.t("dashboard.navigation.guide")}
          />
        </div>

        <div className="col-span-3 p-4 invisible lg:visible relative mt-10">
          <GuideNavigation
            setOpenNavigationItem={this.setOpenNavigationItem}
            openNavItem={this.state.openNavItem}
          ></GuideNavigation>
        </div>

        <div className="col-span-9 p-4">

          <div className="mt-8" id="return-types">
            <h2 className="text-3xl font-bold">Return Types</h2>
            <p>Defines the types that can be returned by the API</p>

            {/* <Product> */}
            <div id="return-types-organisation" className="mt-10">
              <h3 className="text-2xl font-bold">Product</h3>
              <p>The object is returned by the Product endpoints.</p>

              <h4 className="font-bold text-lg mt-4">id</h4>
              <p>The ID of the product.</p>

              <h4 className="font-bold text-lg mt-4">propertyId</h4>
              <p>The ID of the property this product is associated with.</p>

              <h4 className="font-bold text-lg mt-4">provisioned</h4>
              <p>If this product is currently provisioned.</p>

              <h4 className="font-bold text-lg mt-4">firmwareVersion</h4>
              <p>The current firmware version this product is using.</p>

              <h4 className="font-bold text-lg mt-4">lastReadingAt</h4>
              <p>The date of the last reading this product submitted.</p>

              <h4 className="font-bold text-lg mt-4">lastCleanedAt</h4>
              <p>The date this product was last cleaned.</p>

              <h4 className="font-bold text-lg mt-4">createdAt</h4>
              <p>The date the product was created.</p>

              <h4 className="font-bold text-lg mt-4">updatedAt</h4>
              <p>The date the product was last updated.</p>

              <div className="mt-8">
                <code lang="typescript">
                  <pre>
                    {`
  id: "6991b7cd-84d3-405e-af71-88ac293ac9c2";
  propertyId: "6991b7cd-84d3-405e-af71-88ac293ac9c2",
  provisioned: true,
  firmwareVersion: "B02.30 T1",
  lastReadingAt: "2022-01-01T12:00:00.000Z",
  lastCleanedAt: "2022-01-01T12:00:00.000Z",
  createdAt: "  Date",
  updatedAt: "2022-01-01T12:00:00.000Z"
}`}
                  </pre>
                </code>
              </div>

              <div className="mt-8">
                <h4 className="font-bold text-xl mt-4">
                  Advanced Product options.
                </h4>
                <p>
                  These are made available based on the payment tier, free
                  accounts do not have access to these options.
                </p>

                <h4 className="font-bold text-lg mt-4">battery</h4>
                <p>The battery level of the Product.</p>

                <h5 className="font-bold mt-4">1</h5>
                <p>
                  The battery is below 10% and requires changing as soon as
                  possible.
                </p>
                <h5 className="font-bold mt-4">2</h5>
                <p>
                  The battery is below 30% and will require changing in the next
                  few months.
                </p>
                <h5 className="font-bold mt-4">3</h5>
                <p>The battery level is below 50%.</p>
                <h5 className="font-bold mt-4">4</h5>
                <p>The battery is at full power.</p>

                <h4 className="font-bold text-lg mt-4">magnetite</h4>
                <p>The magnetite level of the Product.</p>

                <h5 className="font-bold mt-4">1</h5>
                <p>The magnetite is level is minimal.</p>
                <h5 className="font-bold mt-4">2</h5>
                <p>The magnetite level is above 50%.</p>
                <h5 className="font-bold mt-4">3</h5>
                <p>
                  The magnetite is above 75% and will require emptying in the
                  next few months.
                </p>
                <h5 className="font-bold mt-4">4</h5>
                <p>
                  The magnetite is above 90% and requires emptying as soon as
                  possible.
                </p>

                <code lang="typescript">
                  <pre>
                    {`{
  // The current state of the battery level
  battery?: 1,
  // The current state of the magnetite level
  magnetite?: 1
}`}
                  </pre>
                </code>
              </div>
            </div>
            {/* </Product> */}

            {/* <ProCheck> */}

            <div id="return-types-procheck" className="mt-10">
              <h3 className="text-2xl font-bold">ProCheck</h3>
              <p>The object returned by the ProCheck endpoint.</p>

              <h4 className="font-bold text-lg mt-4">expires</h4>
              <p>Service next due date.</p>

              <h4 className="font-bold text-lg mt-4">pass</h4>
              <p>If the service passed.</p>

              <h3 className="font-bold text-lg mt-4">recommendations:</h3>
              <p>Overall recommendation.</p>

              <h4 className="font-bold text-lg mt-4">molybdate_pass</h4>
              <p>Inhibitor level ok system is protected, no further action required.</p>

              <h4 className="font-bold text-lg mt-4">iron_pass</h4>
              <p>Corrosion level is moderate, no further action required at this stage.</p>

              <h4 className="font-bold text-lg mt-4">ph_low</h4>
              <p>pH level ok, no further action required.</p>

              <h4 className="font-bold text-lg mt-4">insufficient_inhibitor</h4>
              <p>Insufficient Inhibitor detected top up required. Use Adey MC1+ (or equivalent). Ensure to circulate for 30 minutes in the system and test again.</p>

              <h4 className="font-bold text-lg mt-4">corrosion_level_high</h4>
              <p>Corrosion level is high. Lab test is advised or a full system clean. Adey water test kits available through local merchants or <a href="https://www.adeyspares.co.uk/" className="title underline">adeyspares.co.uk</a>.</p>

              <h4 className="font-bold text-lg mt-4">ph_fail</h4>
              <p>pH level is low or high indicating poor system health. Lab test is advised or a full system clean. Adey water test kits available through local merchants or <a href="https://www.adeyspares.co.uk/" className="title underline">adeyspares.co.uk</a>.</p>

              <h4 className="font-bold text-lg mt-4">turbidity_pass</h4>
              <p>Water appearance ok, no further action required.</p>

              <h4 className="font-bold text-lg mt-4">turbidity_fail</h4>
              <p>Water sample failed visual inspection, presence of corrosion detected. Lab test  is advised or a full system clean. Adey water test kits available through local merchants or <a href="https://www.adeyspares.co.uk/" className="title underline">adeyspares.co.uk</a>. MagnaCleanse - Features and Benefits on <a href="https://youtu.be/V1Xz54FdKQU" className="title underline">https://youtu.be/V1Xz54FdKQU</a>.</p>

            </div>
            {/* </ProCheck> */}
          </div>

          <div id="endpoints" className="mt-10">
            <h2 className="text-2xl font-bold">Endpoints</h2>
            <p>Defines the available API endpoints</p>

            <h2 className="text-2xl font-bold mt-8">Authorisation</h2>
            <p>
              All endpoints expect the developers token to be provided in 
              the header of the request as the authorization property.
            </p>
          </div>
        </div>
      </main>
    );
  }

  /**
   * Sets the currently open navigation guide item.
   *
   * @private
   * @param { string } openNavItem
   * @return { Promise<void> }
   * @memberof Guide
   */
  private async setOpenNavigationItem(openNavItem: string): Promise<void> {
    this.setState({ openNavItem });
  }
}

/**
 * Defines the props that will be provided to the GuideNavigation.
 *
 * @interface IGuideNavigationProps
 */
interface IGuideNavigationProps {
  readonly setOpenNavigationItem: (link: string) => void;
  readonly openNavItem: string;
}

/**
 * The guides navigation component.
 *
 * @class GuideNavigation
 * @extends { React.Component<IGuideNavigationProps, object> }
 */
class GuideNavigation extends React.Component<IGuideNavigationProps, object> {
  /**
   * Creates an instance of GuideNavigation.
   *
   * @param { IGuideNavigationProps } props
   * @memberof GuideNavigation
   */
  public constructor(props: IGuideNavigationProps) {
    super(props);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof GuideNavigation
   */
  public render(): JSX.Element {
    return (
      <div
        className="sticky top-10 p-4"
        style={{ backgroundColor: "var(--secondary)" }}
      >
        <List component="nav">
          <h3 className="font-bold text-lg pb-4">Guides</h3>

          <ListItem
            button
            component="a"
            href="#return-types"
            className="pl-4"
            onClick={() => this.props.setOpenNavigationItem("return-types")}
          >
            <ListItemButton
              className="font-bold"
              style={{ color: "rgb(40, 0, 113)" }}
            >
              Types
            </ListItemButton>
          </ListItem>

          <Collapse
            in={this.props.openNavItem === "return-types"}
            timeout="auto"
            unmountOnExit
            className="pl-4"
          >
            <List component="div">
              <ListItem button component="a" href="#return-types-property">
                <ListItemButton>Property</ListItemButton>
              </ListItem>
              <ListItem button component="a" href="#return-types-product">
                <ListItemButton>Product</ListItemButton>
              </ListItem>
              <ListItem button component="a" href="#return-types-procheck">
                <ListItemButton>ProCheck (locked)</ListItemButton>
              </ListItem>
              <ListItem button component="a" href="#return-types-address">
                <ListItemButton>Address</ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            component="a"
            href="#endpoints"
            className="pl-4"
            onClick={() => this.props.setOpenNavigationItem("endpoints")}
          >
            <ListItemButton
              className="font-bold"
              style={{ color: "rgb(40, 0, 113)" }}
            >
              Endpoints
            </ListItemButton>
          </ListItem>

          <Collapse
            in={this.props.openNavItem === "endpoints"}
            timeout="auto"
            unmountOnExit
            className="pl-4"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component="a"
                href="#endpoints-developer"
                className="pl-4"
              >
                <ListItemButton>Endpoints</ListItemButton>
              </ListItem>
              <ListItem button component="a" href="#endpoints-property">
                <ListItemButton>Property</ListItemButton>
              </ListItem>
              <ListItem button component="a" href="#endpoints-product">
                <ListItemButton>Product</ListItemButton>
              </ListItem>
              <ListItem button component="a" href="#endpoints-procheck">
                <ListItemButton>ProCheck</ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

export default withTranslation()(Guide);
