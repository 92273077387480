import './i18n/i18n';
import './index.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import packageJson from '../package.json';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CognitoService } from './services/Cognito.service';

// init Sentry error monitoring
Sentry.init({
  dsn: 'https://bc50a0bae16342f99f3268f1afb347ad@sentry.behold.dev/21',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: `${process.env.REACT_APP_ENVIRONMENT}`,
  release: `adey-sense-developer-client@${packageJson.version}`,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

// init the cognito service.
CognitoService.configure();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
