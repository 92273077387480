import { createSlice } from '@reduxjs/toolkit'

import IAddress from '../interfaces/Address.interface';

/**
 * Defines the shape of the organisation state.
 *
 * @export
 * @interface IOrganisationState
 */
export interface IOrganisationState {
  /**
   * The id of the organisation.
   *
   * Represented as a RFC4122 UUID {https://www.ietf.org/rfc/rfc4122.txt}.
   *
   * @type { (string | null) }
   * @memberof IOrganisationState
   */
  id: string | null;

  /**
   * The name of the organisation.
   *
   * @type { (string | null) }
   * @memberof IOrganisationState
   */
  name: string | null;

  /**
   * The address of the organisation.
   *
   * @type { (IAddress | null) }
   * @memberof IOrganisationState
   */
  address: IAddress | null;
}

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState: {
    name: null,
    id: null,
    address: null,
  } as IOrganisationState,
  reducers: {
    getOrganisation: (state, { payload }): void => {
      Object.assign(state, payload);
    },
    deleteOrganisation: (state): void => {
      Object.assign(state, {
        name: null,
        id: null,
        address: null,
      });
    }
  },
})

export const { getOrganisation, deleteOrganisation } = organisationSlice.actions;

export default organisationSlice.reducer
