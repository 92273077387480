import "./Loading.css";

import { Box, LinearProgress } from "@mui/material";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import adeyBackground from "../assets/images/adey_background.png";
import adeyLogo from "../assets/images/adey_logo_white.png";

/**
 * Loading page to display while the app is checking authentication.
 *
 * @class LoadingPage
 * @extends { React.Component<WithTranslation> }
 */
class LoadingPage extends React.Component<WithTranslation> {
  /**
   * Creates an instance of LoadingPage.
   *
   * @param { WithTranslation } props
   * @memberof LoadingPage
   */
  public constructor(props: WithTranslation) {
    super(props);
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof LoadingPage
   */
  public render(): JSX.Element {
    return (
      <Box position="absolute" minWidth="100%" minHeight="100vh">
        <img
          src={adeyBackground}
          className="adey-background h-screen bottom-0 fixed"
          alt={this.props.t("login.imgAlt.adeyBackground")}
        />

        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          minWidth="100%"
          zIndex="2"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <img
              className="mx-auto mb-5 max-w-2xl	"
              src={adeyLogo}
              alt={this.props.t("dashboard.navigation.brand.imgAlt")}
            />
            <LinearProgress />
          </Box>

          <Box width="100%" className="max-w-lg">
            <LinearProgress />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withTranslation()(LoadingPage);
